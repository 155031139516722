<template>
  <div id="template-div">
    <Main />
    <Socials />
    <WorkPreview />
    <Expertise />
  </div>
</template>

<script>
import Vue from 'vue';
import Main from "@/components/Main.vue";
import Socials from "@/components/Socials.vue";
import WorkPreview from "@/components/WorkPreview.vue";
import Expertise from "@/components/Expertise.vue";

export default Vue.component('Home', {
  components: {Main, Socials, WorkPreview, Expertise}
});
</script>

<style lang="scss">
#template-div {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--default-space);
}

.card-container, .card-container-horizontal {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--default-space);
}
.card-container-horizontal {
  flex-flow: row wrap;
}

.card {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  gap: var(--default-space);
  padding: calc(var(--default-space)*2);
  background-color: var(--grey);
  border-radius: var(--default-space);
}
</style>

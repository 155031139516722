<template>
  <div class="card-container-horizontal" id="socials">
    <a class="card" v-scroll-reveal="{delay: 1050}" id="main" target="_blank" href="https://x.com/ilies_t" rel="noreferrer nofollow">x/twitter</a>
    <a class="card" v-scroll-reveal="{delay: 1100}" id="main" target="_blank" href="https://linkedin.com/in/ilies-t" rel="noreferrer nofollow">linkedin</a>
    <a class="card" v-scroll-reveal="{delay: 1150}" id="main" target="_blank" href="https://github.com/ilies-t" rel="noreferrer nofollow">github</a>
    <a class="card" v-scroll-reveal="{delay: 1200}" id="main" target="_blank" href="https://medium.com/@ilies.t" rel="noreferrer nofollow">medium</a>
    <a class="card" v-scroll-reveal="{delay: 1250}" id="main" target="_blank" href="https://www.npmjs.com/~ilies" rel="noreferrer nofollow">npm</a>
    <a class="card" v-scroll-reveal="{delay: 1300}" id="main" target="_blank" href="https://stackoverflow.com/users/13034990/ilies-t" rel="noreferrer nofollow">stack overflow</a>
    <a class="card" v-scroll-reveal="{delay: 1350}" id="main" target="_blank" href="https://foundation.app/@ilies.t" rel="noreferrer nofollow">foundation</a>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.component('Socials', {});
</script>

<style lang="scss" scoped>
#contact-me {
  height: 100%;
  background-color: var(--black);
  color: var(--white);

  > a {
    background-color: var(--white);
    color: var(--black);
    padding: 20px 10px;
    text-align: center;
    border-radius: calc(var(--default-space)/2);
    font-family: 'wide', sans-serif;
  }
}

#socials {
  > a {
    text-align: center;
    flex-grow: 1;
    padding: var(--default-space) calc(var(--default-space)/2);
    border-radius: calc(var(--default-space)/2);

    &:hover {
      background-color: var(--black);
      color: var(--white);
    }
  }
}

</style>

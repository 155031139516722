<template>
  <div id="app">
    <Header/>
    <router-view/>
    <div id="bg-transparent"></div>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import VueScrollReveal from 'vue-scroll-reveal';

/**
 * Scroll reveal.
 */
Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal',
  duration: 800,
  scale: 1,
  delay: 500,
  distance: '50px',
  cleanup: true
});

export default Vue.extend({
  name: 'App'
});

</script>

<style lang="scss">
@import "assets/styles/index";
#app {
  padding: 50px 5%;
}
</style>

<template>
  <div class="card-container-horizontal" id="work-preview">
    <div class="card" v-scroll-reveal="{delay: 1000}">
      <h2>work preview</h2>
    </div>
    <div class="card-container">
      <a
        class="card"
        v-scroll-reveal="{delay: 800}"
        href="https://medium.com/@ilies.t/reverse-engineering-iot-mobile-e-scooters-use-case-1b0a0b44a5e1"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img src="../assets/Medium.png" alt="see on github" />
        <p>reverse engineering iot/mobile — e-scooters use case</p>
      </a>
      <a
        class="card"
        v-scroll-reveal="{delay: 1450}"
        href="https://github.com/ilies-t/espace-files"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img src="../assets/GitHub.png" alt="see on github" />
        <p>espace file, a proof of concept for hybrid decentralization in file storage services</p>
      </a>
      <a
        class="card"
        v-scroll-reveal="{delay: 1400}"
        href="https://github.com/ilies-t/vector-database-food-similarity"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img src="../assets/GitHub.png" alt="see on github" />
        <p>use case of similarity recommendations for food products with chroma vector database</p>
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.component('WorkPreview', {});
</script>

<style lang="scss" scoped>
#work-preview {
  flex-flow: row-reverse nowrap;

  > .card-container {
    width: 100%;

    > .card {
      flex: 1;
      align-items: baseline;

      > img {
        height: 30px;
      }

      &:hover {
        filter: invert(1);
      }
    }
  }

  > .card > h2 {
    writing-mode: vertical-lr;
    text-align: center;
  }
}

@media screen and (orientation:portrait) {

  #work-preview {
    flex-direction: column;

    > .card > h2 {
      writing-mode: initial;
    }

    > .card-container > .card:hover {
      filter: invert(0);
    }
  }
}
</style>
